import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import logo from './logo.svg';
import {initializeFirebase} from './firebase';
import {getCategoriesData, getDetailsData} from './services';
import CopyLogo from './assets/svg/copy-icon.svg';

import './App.scss';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  const pathname  = window?.location?.origin || "https://links.4bytes.in";
  initializeFirebase();
  const [categories, setCategories]: any = useState([]);
  const [details, setDetails]: any = useState({});
  let query:any = useQuery();
  const cardId = query.get("pid");

  useEffect(() => {
    getDetailsData().then(details => {
      setDetails(details);
    })
    getCategoriesData().then(cat => {
      setCategories(cat);
      setTimeout(()=>{
        document.getElementById(cardId)?.scrollIntoView();
      }, 0)
    })
  }, []);
  const handleCopy = (e:any, link:any) => {
    e.preventDefault();
    e.stopPropagation();
    // Copy the text inside the text field
    navigator.clipboard.writeText(link);
  }
  // console.log('categories', categories);
  // console.log('details', details);
  return (
    <div className="App">
      <header className="App-header" style={{backgroundColor: details?.themeColor || "#282c34"}}>
        {details?.title || "Awesome Products - Affiliate Links"}
      </header>
      <div className='container'>
        <div>
          {Object.keys(categories).map((cat: any, index: any) => (
            !!categories[cat].filter((itm:any) => itm?.enabled).length && <div key={cat}>
              <h3 className='capital' id={cat}>{cat.split("-").join(" ")}</h3>
              <div className='cardsWrap'>
                {
                  !!categories[cat].length && categories[cat].filter((itm:any) => itm?.enabled).map((item: any, index: any) => (
                    <a key={item?.cardId} href={item?.link || ''} target={!!item?.downloadLink ? '' : '_blank'} className={`card ${cardId === item?.cardId ? "active" : ""}`} id={item?.cardId} download={!!item?.downloadLink}>
                      <div className='copyIcon' onClick={(event) => handleCopy(event, pathname+"/?pid="+item?.cardId)}>
                        <img src={CopyLogo} title="copy" />
                      </div>
                      <span>
                        <img src={item?.image || ''} />
                      </span>
                      <p>{item?.title || ''}</p>
                      <button>{item?.linkTitle || ''}</button>
                  </a>
                  ))
                }
              </div>
            </div> || <></>
          ))}
          {!Object.keys(categories).length && <p>Coming Soon!</p>}
        </div>
      </div>
    </div>
  );
}

export default App;
