
import { getApp } from "firebase/app";
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";



export const getCategoriesData = async () => {
    try{

        const db = getFirestore();
        const q = query(collection(db, "categories"));
        const querySnapshot = await getDocs(q);
        let data:any = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            // data.push({ ...doc.data(), pid: doc.id });
            data.push({ ...doc.data() });
        });
        return {...data[0]}
        // return data;
    } catch(e: any) {
        console.log(e);
        return [];
    }
}

export const getDetailsData = async () => {
    try{

        const db = getFirestore();
        const q = query(collection(db, "details"));
        const querySnapshot = await getDocs(q);
        let data:any = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            data.push({ ...doc.data(), pid: doc.id });
        });
        return {...data[0]};
    } catch(e: any) {
        console.log(e);
        return [];
    }
}
